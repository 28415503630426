<template>
  <div :class="{ loading: loading }" class="meeting-registration">
    <div v-show="!paypalInitiated" class="w-full md:w-3/4 m-auto mt-12">
      <p class="p-light">
        The 17th Annual ETUG Meeting in 2025 will be held at the
        <a
          class="link"
          target="_blank"
          href="https://book.passkey.com/e/50928800"
          >Hilton Salt Lake City Center Hotel</a
        >
        on October 7th and 8th. Click the
        <a
          class="link"
          target="_blank"
          href="https://book.passkey.com/e/50928800"
          >hotel link</a
        >
        for the special rate. We're excited to get back together. Please add
        your registration details and complete payment to register. Please
        <a class="link" href="https://etusersgroup.org/contact-us"
          >contact us</a
        >
        at 801-567-0456 or jzarbock@smsenergetics.com if you have any questions.
      </p>
    </div>

    <div v-if="paypalInitiated" class="w-full md:w-3/4 m-auto mt-12 mb-12">
      <p class="p-light">
        Your payment to paypal has been initialized, if completed, please make
        sure to verify payment receipt from paypal, if you've paid and you are
        still seeing this text contact
        <strong>admin@smsenergetics.com</strong> to confirm your order. If you
        did not complete your payment, please refresh to start the process
        again.
      </p>
    </div>

    <div class="w-full md:w-3/4 m-auto mt-8">
      <div
        v-show="!paypalInitiated"
        class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div class="mb-4">
          <label class="block text-gray-700 text-sm mb-2" for="name">
            Name, First and Last (required)
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="name"
            id="name"
            type="text"
            v-model="name"
            placeholder=""
            required
          />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 text-sm mb-2" for="name">
            Email (required)
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="email"
            id="email"
            type="email"
            v-model="email"
            placeholder=""
            required
          />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 text-sm mb-2" for="company">
            Company (required)
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="company"
            id="company"
            type="company"
            v-model="company"
            placeholder=""
            required
          />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 text-sm mb-2" for="name">
            Job Title
          </label>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="jobtitle"
            id="jobtitle"
            type="text"
            v-model="jobtitle"
            placeholder=""
          />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 text-sm mb-2" for="name">
            Presentation Title:
          </label>
          <p class="block text-gray-700 text-sm">
            Although not required, a presentations for discussion is a big part
            of our working group. The ET Users Group meeting is not a
            conference. Participants are expected to participate.
          </p>
          <input
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="present"
            id="present"
            type="text"
            v-model="present"
            placeholder=""
          />
        </div>

        <div class="mb-4">
          <label class="block text-gray-700 text-sm mb-2" for="message">
            Any Comments
          </label>
          <textarea
            class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            name="message"
            id="message"
            type="text"
            v-model="message"
            placeholder=""
          >
          </textarea>
        </div>

        <div class="flex items-center justify-between">
          <button
            v-if="!paypalLoaded"
            id="submit"
            @click="proceed"
            style="background-color:#f09a3e;"
            class="text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            type="submit"
          >
            Proceed to payment ($475)
          </button>
        </div>
        <div class="mb-4"></div>
      </div>
      <div>
        <div
          v-show="!completed"
          id="paypal-button-container"
          class="py-4"
        ></div>
        <div v-show="completed">
          <h2 class="text-xl">Order Completed</h2>
        </div>
      </div>
    </div>
    <div class="modal">
      <img src="@/assets/ajax-loader.gif" alt="pending" />
      <!-- Place at bottom of page -->
    </div>
  </div>
</template>

<script>
export default {
  name: "MeetingRegistration",
  data: function() {
    return {
      loading: false,
      completed: false,
      paypalInitiated: false,
      name: "",
      email: "",
      jobtitle: "",
      company: "",
      present: "",
      message: "",
      paypalLoaded: false,
      buyerDetails: {
        first_name: "",
        last_name: "",
        street: "",
        apt: "",
        city: "",
        state: "",
        zip: "",
        orderId: null
      },
      roleFilter: {
        admin: true,
        "current-meetings": true,
        "friction-test": true,
        "impact-test": true,
        "esd-test": true,
        "thermal-test-dsc": true,
        "thermal-test-sbat": true,
        "koenen-test": true,
        "detonator-test": true,
        "all-meetings": true
      }
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    proceed() {
      let messageData = {
        headerinfo:
          "ET Users Group Registration Info from the meeting registration page",
        // TODO
        /* Trevor, can you add their user info that they are logged in with like their user name, and their roles? */
        // Don't really have a user name so we can show the email along with the one already filled out.
        name: this.name,
        email: this.email,
        company: this.company,
        jobtitle: this.jobtitle,

        present: this.present,
        message: this.message
      };

      if (!messageData.name) {
        alert("Name is required");
        return false;
      }
      if (!messageData.email) {
        alert("Email is required");
        return false;
      }
      if (!messageData.company) {
        alert("Company is required");
        return false;
      }

      let nameSplit = messageData.name.split(" ");

      let vueThis = this;
      var PAYPAL_SCRIPT =
        "https://www.paypal.com/sdk/js?client-id=AavPc9PEmyl9b6y7aWQwXWhOK2oNytnwW-fqykd401lO8pV-ywbS5auZeR29KSbwBhwfCcCaDTUFzU1Z";
      // Sandbox: AaraCJMzUuJSIfi6scJvkwK-02bYsmiyPT59wcGlFjLtlc_gdFvvljWc1vgpMiUY1zqp16TMXYYJI5Hj
      // Sandbox Test Card: Visa	4111111111111111	MM/YY in the Future	123
      var script = document.createElement("script");
      script.setAttribute("src", PAYPAL_SCRIPT);
      document.head.appendChild(script);
      script.onload = () => {
        this.paypalLoaded = true;
        window.paypal
          .Buttons({
            style: {
              layout: "vertical",
              color: "gold",
              shape: "rect",
              label: "checkout"
            },
            createOrder: (data, actions) => {
              this.paypalInitiated = true;
              //this.loading = true;
              return actions.order.create({
                payer: {
                  email_address: messageData.email,
                  name: {
                    given_name: nameSplit[0],
                    surname: nameSplit[nameSplit.length - 1] || ""
                  }
                },
                purchase_units: [
                  {
                    amount: {
                      value: "475.00"
                    },
                    description: "ETUG Meeting"
                  }
                ]
              });
            },
            onApprove: function(data, actions) {
              return actions.order.capture().then(function(details) {
                vueThis.paypalInitiated = false;
                vueThis.orderId = details.id;
                details.messageData = messageData;
                details.userData = {
                  roles: Object.keys(
                    vueThis.$store.getters["user/userClasses"]
                  ).join(" "),
                  userEmail: vueThis.$store.state.user.user.email
                };
                fetch("https://etusersgroup.org/orderCompleted", {
                  method: "POST", // *GET, POST, PUT, DELETE, etc.
                  mode: "no-cors", // no-cors, *cors, same-origin
                  headers: {
                    "Content-Type": "application/json"
                  },
                  body: JSON.stringify(details) // body data type must match "Content-Type" header
                });
                alert(
                  "Transaction completed Order #: " + details.id
                  //"Transaction completed by " + details.payer.name.given_name
                );
                vueThis.completed = true;
                vueThis.loading = false;
              });
            },
            onCancel: () => {
              vueThis.paypalInitiated = false;
              vueThis.loading = false;
            },
            onError: () => {
              vueThis.paypalInitiated = false;
              let details = {
                id:
                  "unknown, please verify payment as there may have been an error or they payment was cancelled out of."
              };
              details.messageData = messageData;
              details.userData = {
                roles: Object.keys(
                  vueThis.$store.getters["user/userClasses"]
                ).join(" "),
                userEmail: vueThis.$store.state.user.user.email
              };
              details.payer = {
                name: {
                  given_name: "Not received from paypal",
                  surname: ""
                },
                email_address: "Not received from paypal"
              };
              fetch("/orderCompleted", {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "no-cors", // no-cors, *cors, same-origin
                headers: {
                  "Content-Type": "application/json"
                },
                body: JSON.stringify(details) // body data type must match "Content-Type" header
              });
              alert(
                "Please verify that your paypal payment went through.  \nCheck for a confirmation email from paypal.  \nIf the payment didn't go through please try again or contact admin@smsenergetics.com"
              );
              vueThis.completed = true;
              vueThis.loading = false;
            }
          })
          .render("#paypal-button-container")
          .then(() => {
            /*             let p = document.createElement("p"),
              t = document.createTextNode(
                "A debit/credit card option is available via PayPal"
              );
            p.appendChild(t);
            p.className = "text-gray-700 text-sm mt-2";
            document.getElementById("paypal-button-container").appendChild(p); */
          });
      };
    }
  }
};
</script>

<style lang="scss">
.meeting-registration {
  #submit:hover {
    background-color: #454d5f !important;
  }

  .modal {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.8) 50% 50% no-repeat;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 32px;
      height: 32px;
      margin-top: -16px; /* Half the height */
      margin-left: -16px; /* Half the width */
      opacity: 0.8;
    }
  }
  &.loading {
    overflow: hidden;
  }
  &.loading .modal {
    display: block;
  }
}
</style>
